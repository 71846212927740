
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { API } from 'client-website-ts-library/services';
import { Testimonial } from 'client-website-ts-library/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TestimonialCard from './TestimonialCard.vue';

@Component({
  components: {
    TestimonialCard,
  },
})
export default class TestimonialCards extends Vue {
  private testimonials: Testimonial[] = [];

  @Prop()
  private readonly filter!: TestimonialFilter;

  mounted() {
    this.updateTestimonials();
  }

  @Watch('filter', { deep: true })
  updateTestimonials() {
    API.Testimonials.Search(this.filter, true).then((testimonials) => {
      this.testimonials = testimonials;

      this.$emit('got_testimonial_count', testimonials.length);

      this.$emit('got_testimonials', testimonials);

      let ratingTotal = 0;

      this.testimonials.forEach((testimonial) => {
        ratingTotal += testimonial.Rating;
      });

      const starRatingTotal = ((ratingTotal / this.testimonials.length) / 100) * 5;

      this.$emit('got_rating_average', Math.round(starRatingTotal * 100) / 100);
    });
  }
}
