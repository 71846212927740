
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { Testimonial, WebsiteLevel } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';

import TestimonialItem from './TestimonialItem.vue';

@Component({
  components: {
    TestimonialItem,
  },
})
export default class TestimonialList extends Vue {
  @Prop({ required: true })
  private readonly filter!: TestimonialFilter;

  private testimonials: Testimonial[] | null = null;

  private loading = true;

  mounted() {
    API.Testimonials.Search(this.filter).then((testimonials) => {
      this.testimonials = testimonials.filter((t) => t.Description);
      this.loading = false;

      this.$emit('got_testimonial_count', testimonials.length);
      this.$emit('got_testimonials', testimonials);
    });
  }
}
